import { Box, Image, Text, Button, Heading } from '@chakra-ui/react';
import Slider from '../SliderWrapper';
import React from 'react';

const HomesSection = ({ cardData }) => {
  return (
    <Box maxW="7xl" mx="auto" py={10} px={4}>
      <Box justifyContent="center" textAlign="center" mb={6}>
        <Heading mb={4}>Homes for Every Style</Heading>
        <Text>Superior finishes, trendy designs, and quality modules at affordable prices.</Text>
      </Box>

      <Slider itemsPerView={3} interval={2500} className="w-full">
        {cardData.map((card) => (
          <Box
            key={card.id}
            w={{ md: "100%", base: "80%" }}
            h="380px"
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            shadow="md"
            bg="white"
            flexShrink={0}
          >
            <Image
              src={card.imageUrl}
              alt={card.title}
              borderRadius="md"
              objectFit="cover"
              position="relative"
              w="100%"
              h="250px"
            />
            <Box px="5">
              <Text fontSize="medium" mt="2">
                {card.title}
              </Text>
              <Box justifyContent="space-between" display="flex" mt="4">
                <Text fontSize="sm" textAlign="left">
                  {card.description}
                </Text>
                <Button fontSize="small" colorScheme="teal" py="2" px="4">
                  Get Free Quote
                </Button>
              </Box>
            </Box>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default HomesSection;
