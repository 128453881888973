import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './styles/global.css'
import { HelmetProvider } from 'react-helmet-async';


const rootElement: any = document.getElementById('root');

const helmetContext = {};
ReactDOM.createRoot(rootElement).render(
    <HelmetProvider context={helmetContext} >
        <App />
    </HelmetProvider>
);