import React, { useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const cards: Card[] = [
    {
        projectType: 'residential',
        location: 'Mumbai',
        length: 25,
        width: 20,
        floors: 2,
        direction: 'North'
    },
    {
        projectType: 'commercial',
        location: 'Delhi',
        length: 30,
        width: 25,
        floors: 3,
        direction: 'East'
    },
    {
        projectType: 'residential',
        location: 'Bangalore',
        length: 20,
        width: 25,
        floors: 1,
        direction: 'South'
    },
    {
        projectType: 'commercial',
        location: 'Hyderabad',
        length: 35,
        width: 40,
        floors: 3,
        direction: 'West'
    },
    {
        projectType: 'residential',
        location: 'Chennai',
        length: 22,
        width: 22,
        floors: 1,
        direction: 'North-East'
    },
    {
        projectType: 'commercial',
        location: 'Pune',
        length: 28,
        width: 18,
        floors: 2,
        direction: 'South-West'
    },
    {
        projectType: 'residential',
        location: 'Kolkata',
        length: 30,
        width: 30,
        floors: 3,
        direction: 'East'
    },
    {
        projectType: 'commercial',
        location: 'Ahmedabad',
        length: 40,
        width: 50,
        floors: 3,
        direction: 'West'
    },
    {
        projectType: 'residential',
        location: 'Jaipur',
        length: 15,
        width: 35,
        floors: 1,
        direction: 'North-West'
    },
    {
        projectType: 'commercial',
        location: 'Surat',
        length: 25,
        width: 20,
        floors: 2,
        direction: 'South-East'
    },
    {
        projectType: 'residential',
        location: 'Lucknow',
        length: 20,
        width: 25,
        floors: 2,
        direction: 'North'
    },
    {
        projectType: 'commercial',
        location: 'Indore',
        length: 32,
        width: 28,
        floors: 3,
        direction: 'South'
    },
    {
        projectType: 'residential',
        location: 'Patna',
        length: 25,
        width: 22,
        floors: 1,
        direction: 'East'
    },
    {
        projectType: 'commercial',
        location: 'Bhopal',
        length: 35,
        width: 25,
        floors: 2,
        direction: 'West'
    },
    {
        projectType: 'residential',
        location: 'Chandigarh',
        length: 20,
        width: 30,
        floors: 2,
        direction: 'North-East'
    }
];


interface Card {
    projectType: 'residential' | 'commercial';
    location: string;
    length: number;
    width: number;
    floors: number;
    direction: string;
    image?: string;
}

const Floorplan = () => {
    const [filteredPlans, setFilteredPlans] = useState<Card[]>(cards);
    const [lengthFilter, setLengthFilter] = useState<number | string>('');
    const [widthFilter, setWidthFilter] = useState<number | string>('');
    const [areaFilter, setAreaFilter] = useState<number | string>('');
    const [floorsFilter, setFloorsFilter] = useState<number | string>('');
    const [directionFilter, setDirectionFilter] = useState<string>('');
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

    const handleFilter = () => {
        let filtered = cards;

        if (lengthFilter) {
            filtered = filtered.filter(card => card.length === Number(lengthFilter));
        }

        if (widthFilter) {
            filtered = filtered.filter(card => card.width === Number(widthFilter));
        }

        if (areaFilter) {
            filtered = filtered.filter(card => card.length * card.width === Number(areaFilter));
        }

        if (floorsFilter) {
            filtered = filtered.filter(card => card.floors === Number(floorsFilter));
        }

        if (directionFilter) {
            filtered = filtered.filter(card => card.direction.toLowerCase() === directionFilter.toLowerCase());
        }

        setFilteredPlans(filtered);
    };

    return (
        <div className="flex flex-col min-h-screen bg-gray-100">
            <Header />
            <div className="flex-grow flex">
                {/* Sidebar Button */}
                <button
                    onClick={() => setSidebarOpen(!sidebarOpen)}
                    className="md:hidden absolute w-14 z-50 p-1.5 h-10 top-32 left-0 flex rounded-r-full bg-gray-800 text-white">
                    {sidebarOpen ? 'Close' : 'Open'} 
                </button>

                {/* Sidebar */}
                <div className={`md:w-1/4 w-full p-4 bg-base-200 rounded ${sidebarOpen ? 'block' : 'hidden md:block'}`}>
                    <h1 className="font-black text-[1rem] md:text-[2rem] my-3 font-[Italiana] text-center">
                        Floorplan
                    </h1>
                    <ul className="menu bg-base-100 divide-y rounded-box shadow">
                        <li className='py-2 text-center cursor-pointer hover:bg-gray-700 focus:text-gray-900 hover:text-white' onClick={() => setFilteredPlans(cards)}>All</li>
                        <li className='py-2 text-center cursor-pointer hover:bg-gray-700 focus:text-gray-900 hover:text-white' onClick={() => setFilteredPlans(cards.filter(card => card.length * card.width >= 500 && card.length * card.width <= 700))}>500 Sqft - 700 Sqft</li>
                        <li className='py-2 text-center cursor-pointer hover:bg-gray-700 focus:text-gray-900 hover:text-white' onClick={() => setFilteredPlans(cards.filter(card => card.length * card.width > 700 && card.length * card.width <= 900))}>700 Sqft - 900 Sqft</li>
                        <li className='py-2 text-center cursor-pointer hover:bg-gray-700 focus:text-gray-900 hover:text-white' onClick={() => setFilteredPlans(cards.filter(card => card.length * card.width > 900 && card.length * card.width <= 1100))}>900 Sqft - 1100 Sqft</li>
                        <li className='py-2 text-center cursor-pointer hover:bg-gray-700 focus:text-gray-900 hover:text-white' onClick={() => setFilteredPlans(cards.filter(card => card.length * card.width > 1100 && card.length * card.width <= 1300))}>1100 Sqft - 1300 Sqft</li>
                        <li className='py-2 text-center cursor-pointer hover:bg-gray-700 focus:text-gray-900 hover:text-white' onClick={() => setFilteredPlans(cards.filter(card => card.length * card.width > 1300 && card.length * card.width <= 1500))}>1300 Sqft - 1500 Sqft</li>
                        <li className='py-2 text-center cursor-pointer hover:bg-gray-700 focus:text-gray-900 hover:text-white' onClick={() => setFilteredPlans(cards.filter(card => card.length * card.width > 1500))}>1500 Sqft and More</li>
                    </ul>

                    <h1 className="font-black text-[1rem] md:text-[2rem] my-3 font-[Italiana] text-center">
                        Filter
                    </h1>

                    <div className="menu bg-base-100 divide-y rounded-box p-4 shadow">
                        <div className='flex mb-2'>
                            <input
                                type="number"
                                placeholder='Length'
                                value={lengthFilter}
                                onChange={(e) => setLengthFilter(e.target.value)}
                                className="input input-bordered w-full mr-2"
                            />
                            <input
                                type="number"
                                placeholder='Width'
                                value={widthFilter}
                                onChange={(e) => setWidthFilter(e.target.value)}
                                className="input input-bordered w-full"
                            />
                        </div>
                        <input
                            type="number"
                            placeholder='Area'
                            value={areaFilter}
                            onChange={(e) => setAreaFilter(e.target.value)}
                            className="input input-bordered w-full mb-2"
                        />
                        <input
                            type="number"
                            placeholder='Floors'
                            value={floorsFilter}
                            onChange={(e) => setFloorsFilter(e.target.value)}
                            className="input input-bordered w-full mb-2"
                        />
                        <input
                            type="text"
                            placeholder='Direction'
                            value={directionFilter}
                            onChange={(e) => setDirectionFilter(e.target.value)}
                            className="input input-bordered w-full mb-2"
                        />
                        <button
                            onClick={handleFilter}
                            className="btn text-slate-100 bg-slate-700 w-full"
                        >
                            Search
                        </button>
                    </div>
                </div>

                {/* Main Content */}
                <div className="flex-1 p-4 flex flex-wrap justify-center h-screen overflow-y-scroll scroll-hide bg-base-100">
                    {filteredPlans.length > 0 ? (
                        filteredPlans.map((plan, index) => (
                            <div key={index} className="card max-h-[500px] w-80 flex-shrink bg-white hover:shadow-xl border  m-4">
                                <div className="card-body p-2">
                                    {/* Image Placeholder */}
                                    <div className="my-4 bg-gray-200 flex items-center justify-center">
                                        <img className="text-gray-400 h-38 object-cover" src='https://www.livehome3d.com/assets/img/articles/how-to-draw-a-floor-plan/floor-plan-of-a-house-with-a-pool.jpg' />
                                    </div>

                                    {/* Plan Type and Location */}
                                    <div className="flex justify-between bg-gray-800 text-white p-2 rounded">
                                        <span className="text-sm">{plan.location}</span>
                                        <span className="text-sm font-bold">{plan.projectType.charAt(0).toUpperCase() + plan.projectType.slice(1)} Project</span>
                                    </div>

                                    {/* Four Parameters */}
                                    <div className="grid grid-cols-2 gap-2">
                                        <div className="bg-gray-100 p-1 rounded text-center">
                                            <p className="text-gray-600 text-sm">{plan.length} ft X {plan.width} ft</p>
                                        </div>
                                        <div className="bg-gray-100 p-1 rounded text-center">
                                            <p className="text-gray-600 text-sm">{plan.width * plan.length} sqft</p>
                                        </div>
                                        <div className="bg-gray-100 p-1 rounded text-center">
                                            <p className="text-gray-600 text-sm">{plan.floors} Floor</p>
                                        </div>
                                        <div className="bg-gray-100 p-1 rounded text-center">
                                            <p className="text-gray-600 text-sm">{plan.direction} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="text-center text-lg">No plans found</p>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Floorplan;