import React, { useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Gallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { GalleryImages } from '../../constants/GalleryImages';
import { useNavigate } from 'react-router-dom';

const ArchitectureGallery = () => {
  const [selectedTab, setSelectedTab] = useState('commercial');

  const selectedImages =
    selectedTab === 'commercial'
      ? GalleryImages.architecture.commercial
      : GalleryImages.architecture.residential;

  const galleryItems = selectedImages.map((option) => ({
    original: option.image,
    thumbnail: option.image,
    description: option.name,
  }));

  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/gallery/interior');
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className="flex-grow">
        <div className="w-11/12 md:w-10/12 mx-auto pb-8 text-black">
          <h1 className="font-black text-[2rem] text-center md:text-[3rem] my-3 font-[Italiana]">
            Architecture
          </h1>

          {/* Stylish Toggle Buttons */}
          <div className="flex mx-auto w-full md:w-1/2 mb-6 items-center justify-around">
            <button
              className={`btn ${selectedTab === 'commercial' ? 'btn-green' : 'btn-outline'} flex-1 mx-1 px-4 py-2 md:py-3 text-sm md:text-base transition-all duration-300`}
              onClick={() => setSelectedTab('commercial')}
            >
              Commercial
            </button>

            <button
              className={`btn ${selectedTab === 'residential' ? 'btn-green' : 'btn-outline'} flex-1 mx-1 px-4 py-2 md:py-3 text-sm md:text-base transition-all duration-300`}
              onClick={() => setSelectedTab('residential')}
            >
              Residential
            </button>
          </div>

          {/* Image Gallery */}
          <Gallery
            items={galleryItems}
            showFullscreenButton={true}
            showPlayButton={true}
            renderItem={(item) => (
              <div style={{ maxWidth: '1000px', margin: 'auto' }}>
                {/* Increase the height of the main slider image */}
                <img
                  src={item.original}
                  alt={item.description}
                  className="w-full h-auto max-h-[600px] object-cover rounded-lg" // Increased height to 600px
                  style={{ aspectRatio: '16/11' }} // Keeping the professional 16:9 ratio
                />
              </div>
            )}
            renderThumbInner={(item) => (
              <div className="flex justify-center items-center">
                {/* Ensure uniform size of thumbnails */}
                <img
                  src={item.thumbnail}
                  alt={item.description}
                  className="w-20 h-20 object-cover rounded-md" // Fixed size for all thumbnails
                />
              </div>
            )}
          />

          {/* Redirect Button */}
          <div className="text-center mt-8">
            <button
              className="btn bg-red-600 text-white font-bold py-2 px-6 rounded-md hover:bg-red-700 transition-all duration-300"
              onClick={handleRedirect}
            >
              Go to Interior Gallery
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ArchitectureGallery;
