// data.ts

const data = {
  features: [
    {
      title: 'Personalised designs',
      imageSrc: 'https://via.placeholder.com/80',
    },
    {
      title: 'Flat 10-year warranty',
      imageSrc: 'https://via.placeholder.com/80',
    },
    {
      title: 'Transparent pricing',
      imageSrc: 'https://via.placeholder.com/80',
    },
  ],

  faqs: [
    { question: "How do I start?", answer: "Visit our website." },
    { question: "Is it free?", answer: "Yes, completely free." },
  ],

  numbers: [
    { number: 75000, label: 'Homes', symbol: 'white', duration: 2500 },
    { number: 3500, label: 'Designers', symbol: 'white', duration: 2500 },
    { number: 40, label: 'Cities', symbol: 'white', duration: 2500 },
  ],

  stepsData: [
    {
      title: "Free Design Consultation",
      description: "Explore the latest design trends with our designer.",
      image: "https://via.placeholder.com/150",
    },
    {
      title: "Dream Home Design",
      description: "Personalized detailed design based on your needs and lifestyle.",
      image: "https://via.placeholder.com/150",
    },
    {
      title: "Booking Confirmation",
      description: "Confirm the Dream Home Design by paying a token.",
      image: "https://via.placeholder.com/150",
    },
    {
      title: "Onsite Services & Installation",
      description: "On-site service works and installation of modular units.",
      image: "https://via.placeholder.com/150",
    },
  ],

  reviews: [
    { name: "User A", review: "Great Service", rating: 5 },
    { name: "User B", review: "Satisfied", rating: 4 },
  ],

  homes: [
    { id: 1, title: "2 BHK", description: "Modern space.", imageUrl: "https://via.placeholder.com/150" },
    { id: 2, title: "3 BHK", description: "Elegant home.", imageUrl: "https://via.placeholder.com/150" },
  ],
};


export default data;
