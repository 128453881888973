import React, { useState } from 'react';
import Loading from '../components/loader/Loading';
import { FcCalculator, FcSalesPerformance } from 'react-icons/fc';
import { useLocation } from 'react-router-dom';
import OtpCard from '../components/cost-calculator/OtpCard';

const CostCalculator = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const location = useLocation();

  console.log(location.state);
  

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    const phoneRegex = /^[0-9]{10}$/; 
    if (!phoneRegex.test(phoneNumber)) {
      setError('Please enter a valid 10-digit phone number');
      setLoading(false);
      return;
    }

    setError('');
    setLoading(true);
    setTimeout(()=>{
      setIsOpen(true);
      setLoading(false);
    },3000)
   
  };

  return (
    <div className="flex justify-center items-center min-h-screen font-inter">
      <img
        src={require("../assets/sign-in.jpg")}
        alt="Logo"
        className="absolute w-full h-screen object-cover"
      />

      <div className="bg-white p-8 rounded-lg absolute shadow-xl w-full max-w-md">
        <div className="text-center mb-6">
          <span className='flex items-center justify-center gap-2 mb-5 text-2xl leading-8 md:text-3xl font-semibold md:leading-[56px] tracking-wide'>
            <h1>Cost</h1>
            <FcSalesPerformance height={150} width={150} />
            <h1>Calculator</h1>
            <FcCalculator />
          </span>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <input
              type="text"
              placeholder="Enter your phone number..."
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={(e) => setPhoneNumber(e.target.value)}
              value={phoneNumber}
            />
            {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
          </div>

          <button
            disabled={loading || !phoneNumber}
            type="submit"
            className="w-full bg-green-800 text-white py-2 rounded-md hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Next
          </button>
        </form>
      </div>

      {loading && 
      <div className='h-screen w-full flex items-center justify-center absolute z-10 bg-white bg-opacity-40 backdrop-blur'>
        <Loading />
      </div>
      }

      {isOpen && <OtpCard phone={phoneNumber}/>}
    </div>
  );
}

export default CostCalculator;
