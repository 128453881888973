"use client";

import React from "react";
import Lottie from "lottie-react";
import * as animationData from "../../assets/lottie/loader/loader.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function Loading() {
  return (
    <div
      className="w-full h-[60vh] z-[100000] flex justify-center items-center "
    >
      <Lottie {...defaultOptions} className="w-1/4 rounded-2xl shadow-xl" />
    </div>
  );
}
