import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { IoIosArrowDown, IoIosMenu, IoIosClose } from "react-icons/io";
import Dropdown from "./Dropdown";
import { RxAvatar } from "react-icons/rx";
export default function Header() {
  const { user } = React.useContext(AppContext);
  const [menuOpen, setMenuOpen] = useState(false);

  const offerings = [
    {
      name: "Interior",
      link: "/interior"
    },
    {
      name: "Architecture",
      link: "/architecture"
    }
  ];

  const designIdeas = [
    {
      name: "Exterior Design",
      link: "/design/exterior"
    },
    {
      name: "Landscape Design",
      link: "/design/landscape"
    },
    {
      name: "Floorplan",
      link: "/design/floorplan"
    }
  ];

  const otherServices = [
    {
      name: "Turnkey Construction",
      link: "/turnkey-construction"
    },
    {
      name: "Site Supervision and Quality Audit",
      link: "/site-supervision-and-quality-audit"
    }
  ];

  const moreOptions = [
    {
      name: "Cost Calculator",
      link: "/cost-calculator"
    },
    {
      name: "Partner With Us",
      link: "https://b2b.designelementary.in/"
    },
    {
      name: "Execution Cities",
      link: "/cities"
    }
  ];

  React.useEffect(() => {}, [user]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="w-11/12 md:w-full md:px-20 mx-auto relative flex justify-between bg-white z-[100000002] items-center py-3">
      <Link to="/" className="w-auto">
        <img
          src={require("../assets/navlogo.svg").default}
          alt="Design Elementary"
          width={190}
          height={16}
        />
      </Link>

      {/* Hamburger Icon */}
      <div className="md:hidden flex gap-3 justify-center items-center">
        <Link
          to="/user/profile/design-ai"
          className="font-semibold active:bg-transparent text-[16px] lg:text-[16px] capitalize mx-2 border-2 text-gray-900 bg-gradient-to-r from-red-200 via-red-300 to-yellow-200 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-red-100 dark:focus:ring-red-400 py-2 px-2 rounded-md transition-all duration-700 "
        >
          DesignAI
        </Link>
        <button onClick={toggleMenu}>
          {menuOpen ? <IoIosClose size={30} /> : <IoIosMenu size={30} />}
        </button>
      </div>

      {/* Desktop Menu */}
      <div className="hidden md:block tooltip px-2 py-2 bg-white text-black tooltip-bottom tooltip-error before:text-white w-1/2 md:w-auto">
        <div className="flex text-xl md:text-base">
          <Dropdown label="Offerings" items={offerings} width="200px" />
          <Dropdown label="Design Ideas" items={designIdeas} width="200px" />
          <Dropdown label="Other Services" items={otherServices} width="350px" />
          <Dropdown label="More" items={moreOptions} width="200px" />
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={`fixed top-0 right-0 h-full w-full bg-white z-[100000] transition-transform duration-300 ease-in-out transform ${
          menuOpen ? "translate-x-0" : "translate-x-full"
        } md:hidden`}
      >
        <div className="flex flex-col items-center">
          {/* Logo at the top of the mobile menu */}
          <div className="w-full bg-white p-4 flex gap-5 justify-center">
            <img
              src={require("../assets/navlogo.svg").default}
              alt="Design Elementary"
              className="w-8/12"
            />
            <button onClick={toggleMenu}>
              <IoIosClose size={30} />
            </button>
          </div>
          <div className="flex flex-col gap-10 items-center pt-4">
            <Link
              to="/login"
              className="font-semibold active:bg-transparent text-black text-[16px] lg:text-[16px] capitalize mx-2  hover:bg-[#7afe75] py-2 px-2 rounded-md"
            >
              Login
            </Link>
            <Dropdown label="Offerings" items={offerings} width="200px" />
            <Dropdown label="Design Ideas" items={designIdeas} width="200px" />
            <Dropdown label="Other Services" items={otherServices} width="350px" />
            <Dropdown label="More" items={moreOptions} width="200px" />
          </div>
        </div>
      </div>

      {/* Login, DesignAI and Avatar Links */}
      <div className="hidden md:flex items-center gap-4 tooltip px-2 py-2 bg-white text-green-400 tooltip-bottom tooltip-error before:text-white w-1/2 md:w-auto">
        {!user.Id && (
          <Link
            to="/login"
            className="font-semibold border-green-300 border-2 border-dashed hover:border-double active:bg-transparent hover:border-white text-black text-[14px] lg:text-[16px] capitalize mx-2 hover:bg-[#7afe75] py-2 md:px-2 rounded-md"
          >
            Login
          </Link>
        )}
        {user.Id && (
          <Link to="https://b2c.designelementary.in" target="_blank" rel="noopener noreferrer">
            <div className="relative group cursor-pointer">
              <RxAvatar
                size={32}
                className="rounded-full transition-transform duration-300 ease-in-out transform group-hover:scale-110 group-hover:shadow-lg group-hover:ring-2 group-hover:ring-gray-300"
              />
              
            </div>
          </Link>
        )}
        <Link
          to="/user/profile/design-ai"
          className="font-semibold active:bg-transparent text-[16px] lg:text-[16px] capitalize mx-2 border-2 text-gray-900 bg-gradient-to-r from-red-200 via-red-300 to-yellow-200 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-red-100 dark:focus:ring-red-400 py-2 px-2 rounded-md transition-all duration-700"
        >
          DesignAI
        </Link>
      </div>
    </div>
  );
}
