import React from 'react';
import { Box, Stack, Heading, Text, VStack, FormControl, FormLabel, Input, Button } from '@chakra-ui/react';

const InteriorOfferComponent = ({ headingText, highlightedText, subText, video,headingText2 }:{
    headingText: string;
    highlightedText: string;
    subText: string;
    video?: string;
    headingText2?:string;
}) => {
    return (
        <Box position="relative" w="100%" h="90vh">
        {/* Video Background */}
        <video
          autoPlay
          muted
          loop
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            zIndex: '10',
          }}
        >
          <source
            src={video}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      
        {/* Dark Overlay */}
        <Box
          position="absolute"
          top="0"
          left="0"
          w="100%"
          h="100%"
          bg="black"
          opacity="0.5"
          zIndex="1"
        />
      
        {/* Content over Video */}
        <Stack
          direction={{ base: 'column', lg: 'row' }} // Stack vertically on smaller screens, side-by-side on large screens
          align="center"
          justify="space-around"
          spacing={8}
          position="relative"
          zIndex="50"
          h="90%"
          p={4}
        >
          {/* Left Section (Text) */}
          <Stack spacing={4} align="left" textAlign="left" color="white" maxW="lg">
            <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
              {headingText}{' '}
              <Box as="span" color="yellow.400">
                {highlightedText}
              </Box>{' '}
             {headingText2}{' '}
            </Heading>
            <Text textAlign="left" fontSize={{ base: 'lg', md: 'xl' }}>
              {subText}
            </Text>
          </Stack>
      
          {/* Right Section (Form) */}
          <VStack
            spacing={6}
            bg="white"
            p={8}
            boxShadow="lg"
            borderRadius="md"
            w={{ base: '100%', lg: 'md' }} // Full width on small screens, fixed width on large screens
          >
            {/* Form Title */}
            <Heading fontSize="2xl" textAlign="center">
              Design for every Budget
            </Heading>
      
            {/* Form Fields */}
            <FormControl id="name" isRequired>
              <FormLabel>Name</FormLabel>
              <Input type="text" placeholder="Enter your name" />
            </FormControl>
      
            <FormControl id="phone" isRequired>
              <FormLabel>Phone Number</FormLabel>
              <Input type="tel" placeholder="Enter your phone number" />
            </FormControl>
      
            {/* Submit Button */}
            <Button colorScheme="teal" size="lg" width="100%">
              Get Free Quote
            </Button>
      
            {/* Disclaimer */}
            <Text fontSize="sm" color="gray.500" textAlign="center">
              By submitting this form, you agree to the privacy policy & terms and conditions.
            </Text>
          </VStack>
        </Stack>
      </Box>
      
    );
};

export default InteriorOfferComponent;
