
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

interface OtpCardProps {
  phone: string;
}

const OtpCard: React.FC<OtpCardProps> = ({ phone }) => {

  const navigate = useNavigate();

  const [otp, setOtp] = useState<string[]>(new Array(6).fill(''));

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    if (/^[0-9]?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index < 5) {
        const nextSibling = document.getElementById(`otp-${index + 1}`);
        if (nextSibling) {
          (nextSibling as HTMLInputElement).focus();
        }
      }
    }
  };

  const handleVerify = () => {

  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded-lg shadow-xl w-full max-w-md">
        <div className="text-center mb-6">
          <h1 className="text-2xl font-bold text-gray-800">Verify</h1>
          <p className="mt-2 text-gray-600">Your code was sent to you via phone number</p>
        </div>
        <div className="flex justify-center mb-4">
          {otp.map((data, index) => (
            <input
              autoComplete="off"
              key={index}
              id={`otp-${index}`}
              type="text"
              maxLength={1}
              value={data}
              onChange={(e) => handleChange(e, index)}
              className="w-10 h-10 text-center text-2xl border border-gray-300 rounded-md mx-1"
            />
          ))}
        </div>
        <button
          className="w-full bg-green-500 text-white py-2 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600"
          onClick={handleVerify}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default OtpCard;
