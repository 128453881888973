
import React from "react";
import ProfileDesigns from "../../../../components/pages/my-designs/ProfileDesigns";
import ProfileLayout from "../layout";

export default function MyDesigns() {
  return (
    <>
      <link rel="canonical" href="https://www.designelementary.com/user/profile/my-designs" />
      <ProfileLayout>
        <ProfileDesigns  />
      </ProfileLayout>
    </>
  );
}
