// TemplatePage.tsx
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HowItWorksSection from '../components/template/HowItWorks';
import ReviewsSection from '../components/template/Reviews';
import FAQSection from '../components/template/FAQSection';
import NumbersSection from '../components/template/NumbersSection';
import HomesSection from '../components/template/Homes';
import KeyFeatures from '../components/template/KeyFeatures';
import InteriorOfferComponent from '../components/template/TopSection';

// Import the data from data.ts
import data from '../constants/Template';

const TemplatePage = () => {
  const { features, faqs, numbers, stepsData, reviews, homes } = data ;
  return (
    <>
      <Header />
      <InteriorOfferComponent
        headingText="Bring home"
        highlightedText="beautiful"
        subText="Crafting Beauty in Every Corner"
      />

      <KeyFeatures
        features={features}
        title="Key Features"
      />

      <NumbersSection
        title="Let Our Numbers Do the Talking!"
        numbers={numbers}
      />

      <HowItWorksSection
        title="How It Works"
        steps={stepsData}
      />

      <ReviewsSection
        reviewsData={reviews}
      />

      <FAQSection
        faqData={faqs}
      />

      <HomesSection
        cardData={homes}
      />

      <Footer />
    </>
  );
};

export default TemplatePage;
