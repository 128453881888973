import React from "react";
import Header from "../Header";
import Footer from "../Footer";

const CareerPage = () => {
    return (
        <div className="relative">
            <Header />
            <div className="max-w-[96rem] mx-auto mt-8 p-4">
                <h1 className="uppercase text-4xl font-bold text-center">
                    Career Opportunity
                </h1>
                <section className="mt-8 space-y-4">
                    <h2 className="text-xl font-semibold">Join Our Team at DE!</h2>
                    <p className="text-lg">
                        Welcome to Design Elementary, where creativity meets innovation! At
                        Design Elementary, we believe in the transformative power of design
                        and its ability to elevate living spaces. We are a dynamic team of
                        creative minds, architects, designers & technologists who are
                        passionate about redefining interior & architecture designs using
                        technology.
                    </p>

                    <h2 className="text-xl font-semibold">Why Design Elementary?!</h2>
                    <div className="space-y-2">
                        <p className="text-lg">
                            <strong className="text-violet-500">Innovation Driven:</strong> At Design Elementary, we are
                            at the forefront of the design revolution. We encourage our team
                            to think outside the box, push boundaries, and explore innovative
                            solutions that redefine interior, architecture or structural
                            design.
                        </p>
                        <p className="text-lg">
                            <strong className="text-violet-500">Collaborative Environment:</strong> We thrive in a
                            collaborative and inclusive work environment. Teamwork is at the
                            heart of our success. Every member's input is valued, and together
                            we create spaces that inspire.
                        </p>
                        <p className="text-lg">
                            <strong className="text-violet-500">Career Growth:</strong> We are committed to the
                            professional growth of our team members. Whether you're starting
                            your career or bringing years of experience, Design Elementary
                            provides opportunities for skill development and career
                            advancement.
                        </p>
                        <p className="text-lg">
                            <strong className="text-violet-500">Passionate Team:</strong> Our team is fueled by passion.
                            We love what we do, and it reflects in our designs. If you are
                            passionate about transforming spaces and making a difference,
                            Design Elementary is the place for you.
                        </p>
                    </div>
                </section>
                <iframe src="https://jobs.reczee.com/design-elementary/job-embed"
                    className="w-full min-h-screen max-h-[200vh] h-fit md:m-auto my-2"
                ></iframe>
                <p className="text-xl font-semibold my-20">Join Design Elementary and be a part of a team that's redefining Interior/Architecture design and creating spaces that leave a lasting impression!</p>
            </div>
            <Footer/>
        </div>
    );
};

export default CareerPage;
